.card {
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.name {
  margin: 0;
  font-size: 1rem;
  color: #333;
}

.desc {
  margin: 0.5rem 0 1rem;
  font-size: 0.88rem;
  word-break: keep-all;
  white-space: pre-wrap;
}

.address {
  font-size: 0.75rem;
  color: #777;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.address button {
  padding: 4px 3px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid rgba(125, 125, 125, 0.35);
  font-size: 0.65rem;
  cursor: pointer;
}

.delivery-link {
  padding-right: 12px;
  font-size: 0.75rem;
  text-underline-offset: 2px;
  color: #333;
}

.rated {
  display: flex;
  align-items: center;
  margin: 0.88rem 0;
  font-size: 1.125rem;
  letter-spacing: 3px;
}

.rated span {
  padding-left: 4px;
  font-size: 0.88rem;
  letter-spacing: 1px;
}

.tag-wrap {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 0.75rem;
}

.tag-wrap p {
  margin: 0 10px 0 0;
}

.tags {
  margin: 0;
  padding: 0;
}

.tags li {
  display: inline-block;
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #fdf6f6;
  border-radius: 12px;
  color: #333;
}
