footer {
  width: 100%;
  margin-top: 4rem;
  padding: 10px 30px;
  background: #ecf0f2;
  border-top: 1px solid #e6e6e6;
}

footer ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  width: 50%;
  padding: 15px 0;
}

footer ul li a {
  color: #333;
  text-underline-offset: 3px;
}

@media (max-width: 600px) {
  footer ul li {
    width: 100%;
    padding: 10px 0;
  }
}
