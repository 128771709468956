* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: "Noto Sans", sans-serif;
}

.App {
  max-width: 600px;
  margin: 0 auto;
  background: #f9f9f9;
}

button,
input,
textarea {
  font-family: "Noto Sans", sans-serif;
}

.tag-list {
  margin-top: 28px;
  padding: 0 20px 24px;
  padding-bottom: 24px;
}

.title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h2 {
  margin: 0;
  font-size: 1.13rem;
}

.toggle-text {
  font-size: 0.63rem;
}

.toggle {
  display: flex;
  justify-content: center;
  margin: 1px 0;
}

.toggle__input {
  display: none;
}

.toggle__label {
  cursor: pointer;
  width: 30px;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 15px;
  position: relative;
  transition: background-color 0.3s;
}

.toggle__label::before {
  content: "";
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s;
}

.toggle__input:checked + .toggle__label {
  background-color: #b8292f;
}

.toggle__input:checked + .toggle__label::before {
  transform: translateX(14px);
}

.swiper-slide {
  width: auto;
}

.swiper-slide button {
  background: transparent;
  border: 2px solid #b8292f;
  color: #b8292f;
  padding: 0.63rem 1.25rem;
  border-radius: 20px;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;
}

.swiper-slide button:hover {
  background: #b8292f;
  color: white;
}

.swiper-slide.active button {
  background: #b8292f;
  color: white;
  border-color: #b8292f;
}

.card-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 0 20px;
}

.Toastify__toast {
  border-radius: 8px;
  background-color: #b8292f;
  color: white;
  font-weight: bold;
}

.floating-button {
  position: fixed;
  bottom: 150px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #fff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 24px;
  z-index: 998;
}

@media (max-width: 600px) {
  html {
    font-size: 14px;
  }
  .floating-button {
    bottom: 40px;
  }
}

.search-container {
  display: flex;
  align-items: center;
  padding: 15px 20px 0;
}
.search-container .search-input {
  flex: 1 1 auto;
  position: relative;
  padding: 0 30px 0 0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.search-container .search-input input {
  width: 100%;
  padding: 8px 0 8px 16px;
  border: none;
  font-size: 14px;
}
.search-container .search-input input:focus {
  border-color: #a8a8a8;
  outline: none;
}
.search-container .search-input button {
  position: absolute;
  top: 10px;
  right: 8px;
  padding: 0;
  border: none;
  cursor: pointer;
}
.search-container .search-input button img {
  width: 16px;
  height: 16px;
}
.search-container button {
  padding: 8px  0 8px 16px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  color: #b8292f;
}

.text-center {
  text-align: center;
}

.btn-list {
  margin: 0 auto;
  padding: 8px 12px;
  background-color: #b8292f;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}